<template>
  <v-responsive
    :aria-label="alt"
    :role="alt ? 'img' : undefined"
    :aspect-ratio="aspectRatio"
    :width="width"
    :height="height"
    class="v-img v-img--booting"
  >
    <template
      v-if="src"
      #additional
    >
      <nuxt-img
        :src="src"
        :alt="alt"
        preload
        :crossorigin="crossorigin"
        provider="storyblok"
        densities="x1 x2"
        :sizes="sizes"
        loading="lazy"
        :class="['v-img__img', containClasses]"
        :style="{ objectPosition: position }"
        :placeholder="placeholder"
        draggable="false"
        :modifiers="{ ...modifiers, width, height }"
        :height="height"
        :width="width"
      />
    </template>

    <slot />
  </v-responsive>
</template>

<script setup lang="ts">
const {
  crossorigin = false,
  sizes = '100vw',
  aspectRatio = 16 / 9,
  src = '',
  cover,
} = defineProps<{
  alt?: string | undefined
  cover?: boolean
  color?: string
  eager?: boolean
  sizes?: string
  src: string | null
  crossorigin?: boolean | 'anonymous' | 'use-credentials'
  referrerpolicy?: 'no-referrer' | 'no-referrer-when-downgrade' | 'origin' | 'origin-when-cross-origin' | 'same-origin' | 'strict-origin' | 'strict-origin-when-cross-origin' | 'unsafe-url'
  srcset?: string
  position?: string
  aspectRatio?: string | number
  height?: number | string
  width?: number | string
  modifiers?: Partial<{
    width: number
    height: number
    fit: string
    format: string
    smart: boolean
    quality: number
    blur: number
    filters: {
      fill: string
      focal: string
    }
  }>
}>()

const img = useImage()

const containClasses = computed(() => ({
  'v-img__img--cover': cover,
  'v-img__img--contain': !cover,
}))

// const meta = await img?.getMeta(props.src, { provider: 'storyblok' })

// const ratio = computed(() => {
//   return props.aspectRatio /*|| meta.ratio*/ || 0
// })
const placeholder = computed(() => img(src, {
  quality: 20,
  blur: 5,
  width: 50,
}, {
  provider: 'storyblok',
}))

// import { useImage } from '#image/composables'
//
// const {
//   src: definedSrc = '',
//   eager = false,
//   cover = false,
//   aspectRatio = 16 / 9,
//   alt = '',
//   modifiers = {},
//   sizes,
//   position = 'center center'
// } = defineProps<{
//   src: string|undefined;
//   eager?: boolean;
//   cover?: boolean;
//   aspectRatio?: number|string;
//   alt?: string;
//   position?: string;
//   sizes?: string;
//   modifiers?: Partial<{
//     width: number;
//     height: number;
//     fit: string;
//     format: string;
//     smart: boolean;
//     filters: {
//       fill: string;
//       focal: string;
//     }
//   }>;
// }>()
//
// const img = useImage()
//
// const provider = 'storyblok'
// const options = {
//   provider,
//   modifiers
// }
// const src = computed(() => img(definedSrc, {}, options))
// const lazySrc = computed(() => img(definedSrc, {}, {
//   ...options,
//   modifiers: {
//     ...modifiers,
//     width: 10
//   }
// }))
// const sizeSet = computed(() => img.getSizes(definedSrc, {
//   ...options,
//   sizes
// }))
//
// const computedSizes = toRef(() => sizeSet.value.sizes)
// const srcset = toRef(() => sizeSet.value.srcset)
// const isResponsive = Object.values(sizeSet.value).every(v => v)
//
// useHead({
//   link: [{
//     rel: 'preload',
//     as: 'image',
//     ...(!isResponsive
//       ? { href: src.value }
//       : {
//           href: sizeSet.value.src,
//           imagesizes: sizeSet.value.sizes,
//           imagesrcset: sizeSet.value.srcset
//         })
//   }]
// })
</script>
